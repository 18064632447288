import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import {  Routes, Route, Link } from "react-router-dom";
import Policy from './policy';
import Home from './home';
import "./App.css";

class App extends React.Component {
  render = () => {
    return ( 
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">PEOPLE COIN</a>
          </div>
          <div className="logo">
            <Link to={'/policy'} className="nav-link">Our Policy</Link>
          </div>
          <div className="social">
            <a href="https://twitter.com/peoplecoin_" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com/peoplecoin_" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://twitter.com/peoplecoin_" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a>
            
          </div>
        </div>
        <div className="content">
        <Routes>
        <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="policy" element={<Policy />} />
        {/* <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route> */}
      </Routes>
      
        {/* <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/policy' component={Policy} /> 
          </Switch> */}
        </div>
        <div className="footer">
          <span>made by <a className="underlined" href="/" target="_blank" rel="noopener noreferrer">peoplecoin team</a> </span>
        </div>
      </div>
       
    );
  }
}

export default App;