import React, { Component } from 'react';

class Policy extends Component {
  render() {
    return (
        <div style={{height: '500px', overflowY: 'scroll' }}>
 <div data-aos="fade" data-aos-delay="300" class="page-content aos-init aos-animate">
    <div class="title">
1.	INTRODUCTION</div>
1.1.	Welcome to the People Coin Careers website run by People Coin Online Private Limited and its affiliates (individually and collectively, "People Coin", "we", "us" or "our"). People Coin takes its responsibilities under applicable privacy laws and regulations ("Privacy Laws") seriously and is committed to respecting the privacy rights and concerns of all job applicants, prospective employees, and all users of our People Coin Careers website (the "Site"). We recognize the importance of the personal data you have entrusted to us and believe that it is our responsibility to properly manage, protect and process your personal data. This Privacy Policy (“Privacy Policy” or “Policy”) is designed to assist you in understanding how we collect, use, disclose and/or process the personal data you have provided to us and/or possess about you, whether now or in the future, as well as to assist you in making an informed decision before providing us with any of your personal data. <br/>
<br/>
1.2.	"Personal Data" or "personal data" means data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organisation has or is likely to have access. Common examples of personal data could include name, identification number and contact information. <br/>
<br/>
1.3.	By submitting an application for employment or other employment related query or by visiting and/or accessing the Site, you acknowledge and agree that you accept the practices, requirements, and/or policies outlined in this Privacy Policy, and you hereby consent to us collecting, using, disclosing and/or processing your personal data as described herein. IF YOU DO NOT CONSENT TO THE PROCESSING OF YOUR PERSONAL DATA AS DESCRIBED IN THIS PRIVACY POLICY, PLEASE DO NOT SUBMIT AN APPLICATION FOR EMPLOYMENT, VISIT AND/OR ACCESS THE SITE OR PROVIDE YOUR PERSONAL DATA TO US. If we change our Privacy Policy, we will notify you of those changes including by posting those changes or the amended Privacy Policy on our Site. We reserve the right to amend this Privacy Policy at any time. To the fullest extent permissible under applicable law, your continued use of the Site and other interactions with us in relation to any prospective employment shall constitute your acknowledgment and acceptance of the changes made to this Privacy Policy. <br/>
<br/>
1.4.	Any personal information that is collected in relation to your application or via our Site is covered by the Privacy Policy in effect at the time such information is collected. We may revise this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we will notify you of those changes by posting them on the Site and/or by sending you an email or other notification, and we will update the “Last Updated Date” to indicate when those changes are effective. This Privacy Policy applies to all of People Coin’s operating divisions and its affiliates. <br/>
<br/>
1.5.	This Policy applies in conjunction with other notices, contractual clauses, consent clauses that apply in relation to the collection, storage, use, disclosure and/or processing of your personal data by us and is not intended to override those notices or clauses unless we state expressly otherwise. <br/>
<br/><div class="title">
2.	WHEN WILL People Coin COLLECT PERSONAL DATA? </div>
2.1.	We will/may collect personal data about you: <br/>
<br/>
•	when you access and/or visit our Site; <br/>
•	when you (or your employment agent) submit any job application or form, including, but not limited to, application forms or other forms relating to any of our job postings; <br/>
•	when you interact with us, such as via telephone calls (which may be recorded), letters, fax, face-to-face meetings, interviews, social media platforms, emails or through the Site. This includes, without limitation, through cookies which we may deploy when you access and/or visit our Site; <br/>
•	when you provide documentation or information in respect of your interactions with us; <br/>
•	when you provide us with feedback or respond to surveys; or<br/>
•	when you submit your personal data to us for any reason. <br/>
<br/>
The above does not purport to be exhaustive and sets out some common instances of when personal data about you may be collected. <br/>
<br/><div class="title">
3.	WHAT PERSONAL DATA WILL People Coin COLLECT? </div>
3.1.	The personal data that People Coin may collect includes but is not limited to: <br/>
<br/>
•	name; <br/>
•	email address; <br/>
•	date of birth; <br/>
•	home address; <br/>
•	telephone number; <br/>
•	gender; <br/>
•	images and biometric data, including photographs or audio or video recordings you provide to us; <br/>
•	education, employment and/or salary history, where applicable; <br/>
•	other information contained in your employment application and related materials; <br/>
•	marketing and communications data, such as your preferences in receiving marketing from us and third parties, your communication preferences and history of communications with us, our service providers, and other third parties; <br/>
•	geographic or location data; <br/>
•	any other information about you provided when you access, visit and/or interact with us or the Site; and<br/>
•	aggregate data on content you engage with. <br/>
<br/>
If you do not want us to collect the aforementioned information/personal data, you may opt out at any time by notifying our Data Protection Officer in writing. Further information on opting out can be found in the section below entitled "How can you withdraw consent, remove, request access to or modify information you have provided to us?". Note, however, that opting out or withdrawing your consent for us to collect, use or process your personal data may affect your use of the Services and the Platform, and it may prevent us from being able to consider or process your employment application. <br/>
<br/><div class="title">
4.	COLLECTION OF OTHER DATA</div>
4.1.	As with most websites and mobile applications, your device sends information which may include data about you that gets logged by a web server when you browse our Site. This typically includes without limitation, your Internet Protocol (IP) address, computer/mobile device operating system and browser type, type of mobile device, the characteristics of the mobile device, the unique device identifier (UDID) or mobile equipment identifier (MEID) for your mobile device, the address of a referring web site (if any), and the pages you visit on our website and mobile applications and the times of visit, and sometimes a "cookie" (which can be disabled using your browser preferences) to help the site remember your last visit. The information is also included in anonymous statistics to allow us to understand how visitors use our Site. <br/>
<br/>
4.2.	Our mobile applications may collect precise information about the location of your mobile device using technologies such as GPS, Wi-Fi, etc. We collect, use, disclose and/or process this information for one or more Purposes including, without limitation, location-based services that you request or to deliver relevant content to you based on your location or to allow you to share your location to other users as part of the services under our mobile applications. For most mobile devices, you are able to withdraw your permission for us to acquire this information on your location through your device settings. If you have questions about how to disable your mobile device's location services, please contact your mobile device service provider or the device manufacturer. <br/>

4.3.	We may in some cases collect certain types of sensitive information when permitted by local law or with your consent, such as health/medical information (including disability status), trade union membership information, religion, race or ethnicity, and information on criminal convictions and offences. Such information is collected and used only for specific purposes and where permissible by law, such as to conduct background checks or to comply with applicable policies and legal obligations. <br/>
<br/><div class="title">
5.	COOKIES</div>
5.1.	We or our authorized service providers and advertising partners may from time to time use "cookies" or other features to allow us or third parties to collect or share information in connection with your use of our Site or Services. These features help us improve our Site and the Services we offer, or help us offer new services and features and/or enable us and our advertising partners to serve more relevant content to you, including through remarketing. “Cookies” are identifiers that are stored on your computer or mobile device that record data about computer or device, how and when the Site is used or visited, by how many people and other activity within our Site. We may link cookie information to personal data. Cookies also link to information regarding what web pages you have viewed. This information is used to deliver content specific to your interests, to enable our third party advertising partners to serve advertisements on sites across the internet, and to conduct data analysis and to monitor usage of the Site. <br/>
<br/>
5.2.	You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this you may not be able to use and/or access the full functionality of our Site. Please note that cookie-based opt-outs may not be effective on mobile applications. However, you may opt-out of personalized advertisements on some mobile applications by following the instructions for Android and iOS. <br/>
<br/><div class="title">
6.	HOW DO WE USE THE INFORMATION YOU PROVIDE US? </div>
6.1.	We may collect, use, disclose and/or process your personal data for one or more of the following purposes: <br/>
<br/>
•	to consider and/or process your application with us; <br/>
•	to present you with career opportunities that you might be interested in; <br/>
•	to manage, operate, provide and/or administer your use of and/or access to the Site; <br/>
•	to tailor your experience by providing a faster method for you to submit information to us and allowing us to contact you, if necessary; <br/>
•	to enforce our Terms of Service or any applicable end user license agreements; <br/>
•	to protect personal safety and the rights, property or safety of others; <br/>
•	for identification and/or verification; <br/>
•	to maintain and administer any software updates and/or other updates and support that may be required from time to time to ensure the smooth running of the Site; to deal with or respond to any enquiries from (or purported to be from) you; <br/>
•	to contact you or communicate with you via voice call, text message and/or fax message, email and/or postal mail or otherwise regarding employment opportunities and/or for the purposes of administering and/or managing the Site, such as but not limited to communicating administrative information to you relating to the Site. You acknowledge and agree that such communication by us could be by way of electronic communication or by the mailing of correspondence, documents or notices to you, which could involve disclosure of certain personal data about you to bring about delivery of the same as well as on the external cover of envelopes/mail packages; <br/>
•	to conduct research, analysis and development activities (including, but not limited to, data analytics, surveys, technology development and/or profiling), to analyse how you use the Site, to improve and/or to enhance the Site; <br/>
•	to allow for audits and surveys to, among other things, validate the size and composition of our target audience, and understand their experience with the Site; <br/>
•	for marketing and in this regard, to send you by various mediums and modes of communication such as postal mail, email, location-based services or otherwise, information and materials relating to job offerings that People Coin (and/or its affiliates or related corporations) may have, whether such listings exist now or are created in the future. You can unsubscribe from receiving marketing information at any time by using the unsubscribe function within the electronic marketing material. We may use your contact information to send newsletters or marketing materials from us and from our related companies; <br/>
•	to respond to legal processes or to comply with or as required by any applicable law, governmental or regulatory requirements of any relevant jurisdiction or where we have a good faith belief that such disclosure is necessary, including, without limitation, meeting the requirements to make disclosure under the requirements of any law binding on People Coin or on its related corporations or affiliates or responding to legal claims; <br/>
•	to produce statistics and research for internal and statutory reporting and/or record-keeping requirements; <br/>
•	to carry out due diligence or other screening activities (including, without limitation, background checks) in accordance with legal or regulatory obligations or our risk management procedures that may be required by law or that may have been put in place by us; <br/>
•	where applicable, to establish an employment relationship with you and/or carry out and administer any related obligations, benefits, and/or policies; <br/>
•	to audit our Site; to prevent or investigate any fraud, unlawful activity, omission or misconduct, whether relating to your use of the Site or any other matter arising from your relationship with us, and whether or not there is any suspicion of the aforementioned; <br/>
•	to store, host, back up (whether for disaster recovery or otherwise) of your personal data, whether within or outside of your jurisdiction; and/or<br/>
•	any other purposes which we notify you of at the time of obtaining your consent. (collectively, the “Purposes”). <br/>
<br/>
6.2.	As the purposes for which we will/may collect, use, disclose or process your personal data depend on the circumstances at hand, such purpose may not appear above. However, we will notify you of such other purpose at the time of obtaining your consent, unless processing of the applicable data without your consent is permitted by the Privacy Laws. <br/>
<br/>
6.3.	You acknowledge, consent and agree that we may access, preserve and disclose your personal information and Content if required to do so by law or pursuant to an order of a court or by any governmental or regulatory authority having jurisdiction over People Coin or its affiliates or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) comply with a request from any governmental or regulatory authority having jurisdiction over People Coin or its relevant affiliates; (c) enforce the our Terms of Service or this Privacy Policy; (d) respond to any threatened or actual claims asserted against People Coin or its relevant affiliates or other claim that any Content violates the rights of third parties; (e) respond to your requests for customer service; or (f) protect the rights, property or personal safety of People Coin or its relevant affiliates, its users and/or the public. <br/>
<br/><div class="title">
7.	WHAT INFORMATION DO WE RETAIN AND HOW DOES People Coin PROTECT USER INFORMATION? </div>
7.1.	We implement a variety of physical, administrative and electronic security measures to ensure the reasonable security of your personal data on our systems. User personal data is contained behind secured networks and is only accessible by a limited number of employees who have special access rights to such systems. <br/>
<br/>
7.2.	We will retain personal data in accordance with the Privacy Laws and/or other applicable laws, including as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws, including to maintain accurate employment, payroll, tax or other records. That is, we will destroy or anonymize your personal data when we have reasonably determined that (i) we no longer have a relationship with you, (ii) the purpose for which that personal data was collected is no longer being served by the retention of such personal data; and (iii) retention is no longer necessary for any legal or business purposes. If you withdraw your application for employment, cease using the Site, or your permission to access or use the Site is terminated, and/or otherwise terminate your relationship with us, we may continue storing, using and/or disclosing your personal data in accordance with this Privacy Policy and our obligations under the Privacy Laws. Subject to applicable law, we may securely dispose of your personal data without prior notice to you. <br/>
<br/>
7.3.	Our Site does not have the capability to respond to “Do Not Track” signals received from various web browsers. <br/>
<br/>
<div class="title">8.	DO WE DISCLOSE THE INFORMATION WE COLLECT TO OUTSIDE PARTIES? </div>
<br/>
8.1.	In conducting our business or in considering your employment application, we will/may need to disclose your personal data to our third-party service providers, agents and/or our affiliates or related corporations, and/or other third parties for one or more of the above-stated Purposes. Such third-party service providers, agents and/or affiliates or related corporations and/or other third parties would be processing your personal data either on our behalf or otherwise, for one or more of the above-stated Purposes. Such third parties may include, without limitation: <br/>
<br/>
•	Our subsidiaries, our parent company, affiliates and related corporations; <br/>
•	Contractors, agents, service providers and other third parties we use to support our business. These include but are not limited to those which provide administrative or other services to us such as mailing houses, telecommunication companies, analytics services, advertising and marketing partners, information technology companies and data centres; 
•	In connection with business transactions; <br/>
•	Government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to legal claims and legal processes, protect our rights and/or the rights of other third parties, and/or to prevent or address any activity we consider unlawful, unethical or legal actionable; and<br/>
•	Third parties to whom disclosure by us is for one or more of the Purposes and such third parties would in turn be collecting and processing your personal data for one or more of the Purposes. <br/>
<br/>
8.2.	For the avoidance of doubt, in the event that Privacy Laws or other applicable laws permit an organisation such as us to collect, use or disclose your personal data without your consent, such permission granted by the laws shall continue to apply. Consistent with the foregoing and subject to applicable law, we may use your personal data for recognized legal grounds including to comply with our legal obligations, to perform our contract with you, to achieve a legitimate interest and our reasons for using it outweigh any prejudice to your data protection rights, or where necessary in connection with a legal claim. <br/>
<br/>
8.3.	Third parties may unlawfully intercept, or access personal data transmitted to or contained on the Site, technologies may malfunction or not work as anticipated, or someone might access, abuse or misuse information through no fault of ours. We will nevertheless deploy reasonable security arrangements to protect your personal data as required by the Privacy Laws; however, there can inevitably be no guarantee of absolute security such as but not limited to when unauthorised disclosure arises from malicious and sophisticated hacking by malcontents through no fault of ours. <br/>
<br/><div class="title">
9.	OUR POLICY TOWARD CHILDREN</div>
9.1.	The Site is not intended for children under the age of 13. We do not knowingly collect or maintain any personal data or non-personally-identifiable information from anyone under the age of 13 nor is any part of our Site directed to children under the age of 13. We will remove and/or delete any personal data we believe was submitted by any child under the age of 13. In some jurisdictions, the age limit may be higher pursuant to applicable laws, in which case we comply with applicable age restrictions. <br/>
<br/><div class="title">
10.	DISCLAIMER REGARDING SECURITY AND THIRD-PARTY SITES</div>
10.1.	WE DO NOT GUARANTEE THE SECURITY OF PERSONAL DATA AND/OR OTHER INFORMATION THAT YOU PROVIDE ON THIRD PARTY SITES. We do implement a variety of security measures to maintain the safety of your personal data that is in our possession or under our control. Your personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the personal data confidential. When you access your personal data, we offer the use of a secure server. All personal data or sensitive information you supply is encrypted into our databases to be only accessed as stated above. <br/>
<br/>
10.2.	In an attempt to provide you with increased value, we may choose various third-party websites to link to, and frame within, the Site. These linked sites have separate and independent privacy policies as well as security arrangements. Even if the third party is affiliated with us, we have no control over these linked sites, each of which has separate privacy and data collection practices independent of us. Data collected by such third-party web sites (even if offered on or through our Site) may not be received by us. <br/>
<br/>
10.3.	We therefore have no responsibility or liability for the content, security arrangements (or lack thereof) and activities of these linked sites. These linked sites are only for your convenience and you therefore access them at your own risk. To protect your information we recommend that you carefully review the privacy policies of all third-party services that you access. Nonetheless, we seek to protect the integrity of our Site and the links placed upon each of them and therefore welcome any feedback about these linked sites (including, without limitation, if a specific link does not work). <br/>
<br/><div class="title">
11.	WILL People Coin TRANSFER YOUR INFORMATION OVERSEAS? </div>
11.1.	Your personal data and/or information may be transferred to, stored or processed outside of your country. In most cases, your personal data will be processed in Singapore, where our servers are located. We will only transfer your information overseas in accordance with Privacy Laws. <br/>
<br/><div class="title">
12.	HOW CAN YOU WITHDRAW CONSENT, REQUEST ACCESS TO OR CORRECT INFORMATION YOU HAVE PROVIDED TO US? </div>
12.1.	<u>Withdrawing Consent</u><br/>
<br/>
12.1.1.	You may withdraw your consent for the collection, use and/or disclosure of your personal data in our possession or under our control by sending an email to our Personal Data Protection Officer at <a href="mailto:contact@peoplecoin.network" target="_blank">contact@peoplecoin.network</a>. However, your withdrawal of consent may mean that we will not be able to continue assessing your application as well as any information provided by you to us. <br/>
<br/>
12.1.2.	We may periodically send you newsletters and e-mails that directly promote our Services if you sign up to receive these communications or otherwise consent to such communications. You also have the opportunity to “opt-out” (for example, by following the unsubscribe instructions provided in the e-mails you receive). We may need to send you certain communications regarding us or our services and you may not be able to opt out of those communications (such as communications regarding updates to our Terms of Service, this Privacy Policy, information about billing or payment, or customer service), subject to applicable law. <br/>
<br/>
12.2.	<u>Additional Notice for Users Whose Data is Subject to California Law</u><br/>
<br/>
12.2.1.	For purposes of the California Consumer Privacy Act (“CCPA”), we do not “sell” personal information and in the past twelve months prior to the effective date of this Policy not sold any personal information of our users and/or prospective job applicants, as defined under the CCPA. The California “Shine the Light” law also gives residents of California the right under certain circumstances to opt out of the sharing of certain categories of personal information (as defined in the Shine the Light law) with third parties for their direct marketing purposes. We do not share your personal information with third parties for their own direct marketing purposes. <br/>
<br/>
12.2.2.	The personal information that People Coin collects, uses and/or discloses and has collected, used, and/or disclosed from users and/or prospective job applicants as set forth above in the twelve months prior to the effective date of this Policy, may include personal information as defined under the CCPA, depending on how you have accessed the Site and interacted with us. <br/>
<br/>
12.2.3.	In accordance with the CCPA or other applicable law, you may have the right to: (i) request confirmation of whether we are processing your personal information; (ii) obtain access to or a copy of your personal information; (iii) receive an electronic copy of your personal information or ask us to send that information to another company; (iv) restrict our uses of your personal information, including the right to opt in or opt out of the sale of your personal information to third parties, depending on applicable law; (v) seek correction or amendment of inaccurate, untrue, incomplete, or improperly processed personal information; and (vi) request erasure of personal information held about you by People Coin, subject to certain exceptions prescribed by law. <br/>
<br/>
12.2.4.	If you would like to exercise any of these rights, please contact us as set forth below by sending an email to our Personal Data Protection Officer at <a href="mailto:contact@peoplecoin.network" target="_blank">contact@peoplecoin.network</a>. We will process such requests in accordance with applicable laws. To protect your privacy, we will take steps to verify your identity before fulfilling your request. If you are a California resident, you have the right not to receive discriminatory treatment by us for the exercise of your rights conferred by the CCPA. <br/>
<br/>
12.3.	<u>Other User Rights</u><br/>
<br/>
12.3.1.	If you are located in the European Economic Area, you have the right to exercise certain rights available under applicable data protection laws. We will comply with requests to exercise these rights in accordance with applicable law. Please note that in some circumstances, we may need to keep processing your information for certain legitimate interests or to comply with a legal obligation. You may also lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law. <br/>
<br/>
12.3.2.	For residents of Canada: Where, in accordance with this Privacy Policy, we send or transfer your personal information to another country, that personal information may be available to the applicable foreign government or its agencies under a lawful order made in that foreign country for purposes other than those described herein. <br/>
<br/>
12.4.	<u>Requesting Access and/or Correction of Personal Data</u><br/>
<br/>
12.4.1.	You may request to access and/or correct your personal data currently in our possession or control by submitting a written request to us. We will need enough information from you in order to ascertain your identity as well as the nature of your request so as to be able to deal with your request. Hence, please submit your written request by sending an email to our Personal Data Protection Officer at <a href="mailto:contact@peoplecoin.network" target="_blank">contact@peoplecoin.network</a>.<br/>
<br/>
12.4.2.	We may charge you a reasonable fee for the handling and processing of your requests to access your personal data, where applicable. If we so choose to charge, we will provide you with a written estimate of the fee we will be charging. Please note that in most jurisdictions we are not required to respond to or deal with your access request unless you have agreed to pay the fee. <br/>
<br/>
12.4.3.	We reserve the right to refuse to correct your personal data in accordance with the provisions as set out in Privacy Laws, where they require and/or entitle an organisation to refuse to correct personal data in stated circumstances. <br/>
<br/>
12.4.4.	Please note that certain information may be exempt from such requests under applicable law and that, depending on your data choices, certain services may be limited or unavailable. We will not discriminate against you for exercising your rights. <br/>
<br/><div class="title">
13.	QUESTIONS, CONCERNS OR COMPLAINTS? CONTACT US</div>
13.1.	If you have any questions or concerns about our privacy practices, we welcome you to contact us by e-mail: <a href="mailto:contact@peoplecoin.network" target="_blank">contact@peoplecoin.network</a>, <br/>
<br/>
13.2.	Please include your name, contact information, and the nature of your request so that we can appropriately respond to your communication. <br/>
<br/>



        </div>
        </div>
    );
  }
}

export default Policy;