import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
        <div>
         <div className="title-holder">
            <h1>Website under construction.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:contact@peoplecoin.network">
            <div className="cta">Send us an email</div>
          </a>
        </div>
    );
  }
}

export default Home;